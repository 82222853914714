<div class="progress-bar">
  <span class="progress-bar__progress" style={`width: ${percentage}%`}>
    <span class="visuallyhidden">{percentage} %</span>
  </span>
</div>

<script>
  export let percentage = 0;
</script>

